<template>
  <div id="app">
    <Assistente />
  </div>
</template>

<script>
import Assistente from './components/Assistente.vue'

export default {
  name: 'App',
  components: {
    Assistente
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#app {
  flex: 1;
  background-color: black;
  color: white;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
